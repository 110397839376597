var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"S6bP9-YedxOFc0b5eXTXh"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { env } from "@/env.mjs"
import * as Sentry from "@sentry/nextjs"

Sentry.init({
  dsn: env.NEXT_PUBLIC_SENTRY_DSN ?? "",
  environment: env.NEXT_PUBLIC_SENTRY_ENV ?? "development",
  // Replay may only be enabled for the client-side
  integrations: [Sentry.replayIntegration()],

  // Configures the sample rate for error events,
  // in the range of 0.0 to 1.0. The default is 1.0,
  // which means that 100% of error events will be sent.
  // If set to 0.1, only 10% of error events will be sent.
  // Events are picked randomly.
  sampleRate: 0,  

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1,
})
